import {InjectionToken, Injectable} from '@angular/core';
import {IAppConfig} from './app-config.interface';

export const APP_CONFIG = new InjectionToken<IAppConfig>('app.config');

export const AppConfig: IAppConfig = {
    version: 'v0.0.1',
    appName: 'RSDataTech Billing',
    apiEndpoint: 'api.billing.rsdatatech.com',  //not used yet
    dateTimeFormat: 'MMM d, y, HH:mm:ss',
    dateFormat: 'd MMM yyyy',
    googleClientId: `994657562748-jgm6fut68cbpcbq91n5g9d1hcominmf5.apps.googleusercontent.com`
};
